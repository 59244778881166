// old stuff
import "./min/index"

let app = window.app || {};
app.modules = app.modules || {};
window.app = app;

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as slider from '@elements/slider';
app.modules.slider = slider.initInScope;

import * as ajaxForm from '@elements/ajax-form';
app.modules.demiAjaxForm = ajaxForm.createInitInScope({
    addUrlParams:true,
    fetchHeaders: {'Format':'json'}
}, {
    base: '.js-demi-ajax-form',
    result: '.js-demi-ajax-form__result',
    loading: '.js-demi-ajax-form__loading',
    notifications: '.js-demi-ajax-form__notifications',
    form: '.js-demi-ajax-form__form',
    additionalForm: '.js-demi-ajax-form__additional-form',
    errorArea: '.js-demi-ajax-form__error-area',
    retry: '.js-demi-ajax-form__retry',
    link: '.js-demi-ajax-form__link',
    reset: '.js-demi-ajax-form__reset',
});
// import * as cookiePermissions from '@elements/cookie-permissions';
// app.modules.cookiePermissions = cookiePermissions.initInScope;

import * as acceptCookieService from './acceptCookieService';
app.modules.acceptCookieService = acceptCookieService.initInScope;

import "./consent-overlay";
import "./main";
