/**
 * ExtendAjaxInclude v. 0.3.3
 * Dependencies: ajaxInclude()
 * © 2014 - Gerhard Kanzler (elements.at)
 **/
;(function ( $, window, document, undefined ) {

    var pluginName = "extendAjaxInclude",
        defaults = {
            defaultOffset: 500,
            ttl : 60,
            noStorage: false,
            device : ''
        };

    function ExtendAjaxInclude( element, options ) {
        this.element = element;
        this.options = $.extend( {}, defaults, options) ;

        this._defaults = defaults;
        this._name = pluginName;

        this.scrollHandle = [];
        this.innerHeight = $(window).innerHeight();
        this.hasSessionStorage = window.sessionStorage ? true : false;
        this.hasLocalStorage = window.localStorage ? true : false;

        this.init();
    }

    ExtendAjaxInclude.prototype = {
        init: function() {
            var _self = this;
            this.ajaxInclude();
            $(window).on('resize', function(){
                _self.innerHeight = $(window).innerHeight();
            });

            // Listen to Scroll event
            $(window).on('scroll', function(){
                if( _self.scrollHandle.length ){
                    _self.innerHeight = $(window).innerHeight();
                    _self.ajaxIncludeScrollCallback();
                }
            });
        },

        // AjaxInclude
        ajaxInclude  : function( _obj ){
            var el = $(this.element);
            if( typeof _obj != 'undefined' ){
                el = $(_obj[0]);
            }
            var _this = this,
                hasDataContent = true;
            el.each(function(){
                var _self = $(this),
                    _methods = [ "append", "replace", "before", "after" ],
                    _method;

                for( var ml = _methods.length, i=0; i < ml; i++ ){
                    if( _self.is( "[data-" + _methods[ i ] + "]" ) ){
                        _method = _methods[ i ];

                        if( _this.options.device != '' ) {
                            if (_self.data(_method).match(/\?/)) {
                                el.attr('data-' + _method, _self.data(_method) + '&device=' + _this.options.device);
                            } else {
                                el.attr('data-' + _method, _self.data(_method) + '?device=' + _this.options.device);
                            }
                        }

                        if( typeof _self.data(_method) == 'undefined' ){
                            hasDataContent = false;
                        }

                        if( _method === 'replace' ){
                            _method += 'With';
                        }
                    }
                }


                if( hasDataContent ){
                    var deviceKey = '';
                    if( _this.options.device != '' ){
                        deviceKey = '-' + _this.options.device;
                    }

                    if( _this.hasSessionStorage ){
                        var renewStorage = false,
                            ttl = null;

                        if( _self.is('[data-ttl]') ){
                            ttl = (_self.data('ttl') * 1000);
                            var myTime = new Date();
                            var newTime = myTime.getTime() + ttl;
                            if( window.sessionStorage ){
                                if( sessionStorage.getItem( _self.data('storage') + '--ttl') ){
                                    if( sessionStorage.getItem( _self.data('storage') + '--ttl') < myTime.getTime() ){
                                        renewStorage = true;
                                    }
                                }else{
                                    renewStorage = true;
                                }
                            }

                            if( renewStorage ){
                                myTime = new Date();
                                myTime = myTime.getTime() + ttl;
                                if(window.sessionStorage){
                                    sessionStorage.setItem(_self.data('storage') + '--ttl', myTime);
                                }
                            }
                        }

                        if( !sessionStorage.getItem(_self.data('storage') + deviceKey ) || _this.options.noStorage || renewStorage ){
                            if( _self.is('[data-scrolloffset]') ){
                                if( _self.data('scrolloffset') == '' ){
                                    _self.data('scrolloffset', _this.options.defaultOffset );
                                }
                                _this.scrollHandle.push( _self );
                            }else{
                                _this.callAjaxInclude( _self );
                            }
                        }else{
                            if (_self.is('[data-interaction]')) {
                                return;
                            }
                            var media = _self.attr( "data-media" );
                            if ( !media || ( window.matchMedia && window.matchMedia( media ).matches ) || (_self.data( "fallback" ) !== undefined && !window.matchMedia('(min-width: 0px)').matches)) {

                                var content = sessionStorage.getItem(_self.data('storage') + deviceKey );
                                if( _method === 'replaceWith' ) {
                                    _self['after']( content );
                                    _self.trigger( "ajaxInclude", [ content ] );
                                    _self.remove();
                                } else {
                                    _self[ _method ]( content );
                                    if( typeof _obj == 'undefined' ){
                                        _self.trigger( "ajaxInclude", [ content ] );
                                    }else{
                                        _this.callAjaxInclude( _self, _obj );
                                    }

                                }
                            }

                        }
                    }
                }
            });
            _this.ajaxIncludeScrollCallback();
        }, // end ajaxInclude()

        // Scroll event callback
        ajaxIncludeScrollCallback : function(){
            var _this = this;
            $(this.scrollHandle).each(function( _idx, _obj ){
                var winTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
                if( (_obj.offset().top - _obj.data('scrolloffset')) < winTop+_this.innerHeight ){
                    _this.scrollHandle.splice(_idx, 1);
                    _this.callAjaxInclude( _obj );
                }
            });

        }, // end ajaxIncludeScrollCallback()

        // Ajax Include Call
        callAjaxInclude : function( _self, _obj ){
            var _this = this;
            if( typeof _obj != 'undefined' ){
                _self.trigger('ajaxIncludeUnbind');
            }
            _self.ajaxInclude();
            _self.on('ajaxInclude', function( el, content ){
                try{
                    var deviceKey = '';
                    if( _this.options.device != '' ){
                        deviceKey = '-' + _this.options.device;
                    }
                    if( _self.is('[data-storage]') ){
                        sessionStorage.setItem( _self.data('storage') + deviceKey, content );
                    }
                }catch( e ){
                    sessionStorage.clear();
                    window.location.href = window.location.href;
                }
            });
        } // end callAjaxInclude()
    };

    $.fn[pluginName] = function ( options ) {
        return this.each(function () {
            if (!$.data(this, pluginName)) {
                $.data(this, pluginName,
                    new ExtendAjaxInclude( this, options ));
            }
        });
    };

})( window.jQuery, window, document );