"use strict";

import {setServicePermission} from "@elements/cookie-permissions";

const selectors = {
    btn: '.js-accept-cookie-service'
};

export function initInScope ($scope) {
    let $baseElement = $scope.find(selectors.btn);

    let cookieService = $baseElement.data("cookieServiceOverlayName") || false;
    console.log("cookieService", cookieService);

    if(cookieService) {
        $baseElement.on('click', function() {
            setServicePermission(cookieService, true);
        });
    } else {
        console.warn('cookie-service-overlay: no cookie service name found!')
    }
}

