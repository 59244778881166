

if (typeof console == "undefined") {
    console = {
        log: function (v) {},
        dir: function (v) {},
        debug: function (v) {},
        info: function (v) {},
        warn: function (v) {},
        error: function (v) {},
        trace: function (v) {},
        group: function (v) {},
        groupEnd: function (v) {},
        time: function (v) {},
        timeEnd: function (v) {},
        profile: function (v) {},
        profileEnd: function (v) {}
    };
}

var ns = window['NS'] = {},
    _cfg = _config  || {},
    app = window.app || {};

app.modules = app.modules || {};
window.app = app;

app.modules.isClickable = (function () {
    function isClickable( obj, newTab ){
        var $this = obj,
            link = $this.find('a:first'),
            href = link.attr('href'),
            target = link.attr('target');
        if( target == '_blank' || newTab ){
            window.open( href );
        }else{
            window.location.href = href;
        }
    }

    function initScope($scope) {
        $scope.find('.isClickable').on('tap', function(evt){
            if (!$(evt.target).is('a')) {
                isClickable( $(this));
                evt.preventDefault();
                evt.stopImmediatePropagation();
            }


        });
    }

    return initScope;
}());

app.modules.gallery = function ($scope) {
    $.getScript("/static/js/libs/owl.carousel.min.js").done(function () {
        $scope.find('.js-gallery').each(function () {
            var mainGallery = $(this).find('.js-gallery__main');
            mainGallery.owlCarousel({
                items: 1,
                lazyLoad:true,
                nav: true,
                navText: ['<span class="za-icon za-icon-arrow-left"></span>', '<span class="za-icon za-icon-arrow-right"></span>']
            });

            var thumbContainer = $(this).find('.js-gallery__thumbs');
            thumbContainer.owlCarousel({
                nav:true,
                navText: ['<span class="za-icon za-icon-arrow-left"></span>', '<span class="za-icon za-icon-arrow-right"></span>'],
                responsive:{
                    0:{
                        items:3,
                        margin: 8
                    },
                    768:{
                        items:6,
                        margin: 12
                    }
                }
            });

            thumbContainer.on('tap', '.owl-item', function () {
                $(this).closest('.js-gallery')
                    .find('.js-gallery__main')
                    .trigger('to.owl.carousel', $(this).index());
            });

            thumbContainer.find('.owl-item').eq(0).addClass('is-active');

            mainGallery.on('translate.owl.carousel', function (evt) {
                thumbContainer.find('.owl-item')
                    .removeClass('is-active')
                    .eq(evt.item.index).addClass('is-active');

                var numberOfItems = (matchMedia('(max-width: 767px)').matches) ? '3': '6',
                    page = Math.round(evt.item.index / numberOfItems);

                thumbContainer.trigger('to.owl.carousel', page);
            });
        });
    });

    $.getScript("/static/js/libs/magnific-popup.js").done(function () {

    });
};

app.modules.slider = function ($scope) {
    $.getScript("/static/js/libs/owl.carousel.min.js").done(function () {
        $scope.find('.js-slider').each(function () {
            var mainGallery = $(this);
            mainGallery.owlCarousel({
                items: 1,
                lazyLoad:true,
                nav: true,
                navText: ['<span class="za-icon za-icon-arrow-left"></span>', '<span class="za-icon za-icon-arrow-right"></span>']
            });
        });
    });
};

app.modules.headerSlider = function ($scope) {
    $.getScript( "/static/js/libs/owl.carousel.min.js").done(function () {
        $scope.find('.js-headerSlider').owlCarousel({
            items: 1,
            lazyLoad:true,
            nav: false
        });
    });
};


app.googleMap = (function () {
    var apiLoaded = false,
        apiLoading = false,
        callbacks = [];

    function loadScript() {
        apiLoading = true;
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "http://maps.googleapis.com/maps/api/js?key=AIzaSyAWhPax3eUYuiHcAZGvDbYnyup20mqhsg8&sensor=false&callback=app.googleMap.apiLoadedCallback";
        document.body.appendChild(script);
    }

    function apiLoadedCallback() {
        for(var i= 0, callback; callback = callbacks[i]; i++) {
            callback();
        }

        apiLoading = false;
        apiLoaded = true;
    }

    function loadApi(callback) {
        if (apiLoaded) {
            callback();
        } else {
            callbacks.push(callback);

            if (!apiLoading) {
                loadScript();
            }
        }
    }

    return {
        apiLoadedCallback: apiLoadedCallback,
        loadApi: loadApi
    };

}());

app.modules.googleMap = (function () {
    function GoogleMap (element) {
        var self = this;
        this.element = $(element);
        this.canvas = element.find('.js-google-map__canvas')[0];
        this.map = null;
        this.marker = [];
        app.googleMap.loadApi(function () {
            self.initMap();
        });
    }

    GoogleMap.prototype.initMap = function () {
        var self = this,
            mapOptions = {
                zoom: 8,
                center: new google.maps.LatLng(46.738056, 11.960000),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: true,
                minZoom: 3,
                scrollwheel: false
            };

        this.markerOptions = {
            url: '/static/img/pois/default-active.png',
            size: new google.maps.Size(57, 68),
            origin: new google.maps.Point(0,0),
            anchor: new google.maps.Point(28, 55)
        };

        self.map = new google.maps.Map(self.canvas, mapOptions);

        this.element.on('tap', '.js-google-map__zoom-in', function () {
            self.map.setZoom(++self.map.zoom);
        });

        this.element.on('tap', '.js-google-map__zoom-out', function () {
            self.map.setZoom(--self.map.zoom);
        });

        this.element.on('tap', '.js-google-map__center', function () {
           self.centerMap();
        });

        this.element.on('tap', '.js-close', function (evt) {
            evt.preventDefault();
            self.closeDetail();
        });

        var markerImg = this.element.data('poi-img');
        if (markerImg) {
            this.markerOptions.url = markerImg;
        }

        markerImg = this.element.data('poi-img-active');
        if (markerImg) {
            this.activeMarkerImg = markerImg;
        }

        var poiData = this.element.data('poi');
        if (poiData) {
            this.setMarker(poiData);
            this.centerMap();
        }

        var addressData = this.element.data('address');
        if (addressData) {
            geocoder = new google.maps.Geocoder();
            geocoder.geocode( { 'address': addressData}, function(results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    self.setMarker([{'lat': results[0].geometry.location.lat(), 'lng': results[0].geometry.location.lng()}]);
                    self.centerMap();
                }
            });
        }
    };

    GoogleMap.prototype.setMarker = function (data) {
        var self = this;

        //remove marker
        for (var i = 0, marker; marker = this.marker[i]; i++ ) {
            marker.setMap(null);
        }
        marker = [];

        for (var i= 0, poi; poi = data[i]; i++) {
            if (poi.lat && poi.lng) {
                var poiPosition = new google.maps.LatLng(poi.lat,poi.lng),
                    newMarker = new google.maps.Marker({
                            position: poiPosition,
                            map: this.map,
                            icon: this.markerOptions,
                            dataId: poi.id
                        }
                    );

                this.marker.push(newMarker);

                if (this.hasDetailView) {
                    google.maps.event.addListener(newMarker, 'click', function(e, o) {
                        self.deactivateAllMarker();
                        this.setIcon(self.activeMarkerImg);
                        self.map.setCenter(this.getPosition());
                        self.showDetail(this.dataId);
                    });
                }
            }
        }
    };

    GoogleMap.prototype.centerMap = function () {
        var latlngs = [];

        for (var i= 0, marker; marker = this.marker[i]; i++) {
            latlngs.push(marker.getPosition());
        }

        // Zoom/center map to fit all marker
        if (latlngs.length === 1) {
            this.map.setCenter(latlngs[0]);
        } else {
            var latlngbounds = new google.maps.LatLngBounds();
            for (var i= 0, pos; pos = latlngs[i]; i++) {
                latlngbounds.extend(pos);
            }
            this.map.setCenter(latlngbounds.getCenter());
            this.map.fitBounds(latlngbounds);
        }
    };

    GoogleMap.prototype.deactivateAllMarker = function () {
        for (var i = 0, marker; marker = this.marker[i]; i++ ) {
            marker.setIcon(this.markerOptions.url);
        }
    };

    GoogleMap.prototype.closeDetail = function () {
        this.detailView.removeClass('is-active');
        this.detailView.empty();
    };

    function initScope($scope) {
        if(!matchMedia('(max-width: 767px)').matches) {
            $scope.find('.js-google-map').each(function () {
                new GoogleMap($(this));
            });
        }
    }

    return initScope
}());


app.modules.datepicker = function ($scope) {
    console.log('datepicker');

    var defaultOptions = {
        minDate: 0,
        dateFormat: 'dd.mm.yy'
    };

    if (_config.lang=="de") {
        var deOptions = {
            monthNames: ['Januar', 'Februar', 'M&auml;rz', 'April', 'Mai', 'Juni',
                'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            monthNamesShort: ['Jan', 'Feb', 'M&auml;r', 'Apr', 'Mai', 'Jun',
                'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
            dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
            dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
            weekHeader: 'Wo',
            dateFormat: 'dd.mm.yy'
        };
        defaultOptions = $.extend(defaultOptions, deOptions);
    }

    var datePicker = $scope.find('.js-datepicker').datepicker(defaultOptions);


    var endDate = new Date().getTime()+ 5 * 24 * 60 *60 * 1000;
    var date = new Date(endDate);

    if(date.getMonth()+1 < 10){
        var cero = '0';
    }else{
        var cero = '';
    }

    var endPicker = datePicker.filter('[data-date-picker=end]');
    if (endPicker && endPicker.length) {
        datePicker.filter('[data-date-picker=end]')[0].value = date.getDate() + '.' + cero + (date.getMonth() + 1) + '.' + date.getFullYear();
    }

    datePicker.filter('[data-date-picker=start]').datepicker('option', 'onClose', function () {
        var startDateArr = $(this).val().split('.'),
            startDate = new Date([startDateArr[1], startDateArr[0], startDateArr[2]]),
            defaultDate = new Date(startDate.getTime() + 5 * 24 * 60 *60 * 1000),
            defaultDateStr = defaultDate.getDate() + '.' + (defaultDate.getMonth() + 1) + '.' + defaultDate.getFullYear();

        var endDatePicker = $(this).closest('form, .form').
            find('[data-date-picker=end]').
            eq(0).
            datepicker('option', 'minDate', $(this).val()).
            val(defaultDateStr).
            datepicker('show');
//        var endDatePicker = $(this).nextAll('[data-date-picker=end]').
//            datepicker('option', 'minDate', $(this).val()).
//            datepicker('show');
    });
};

$('.booking-slide.quickfinder form').on('click', function(){
    //e.preventDefault();
    var oneDay = 24 * 60 * 60 * 1000;
    var dep = $('.date.departure');
    var arr = $('.date.arrival');

    var depDate = dep.val().split('.');
    var arrDate = arr.val().split('.');
    var arrTime = new Date([arrDate[1], arrDate[0], arrDate[2]]);
    var depTime = new Date([depDate[1], depDate[0], depDate[2]]);

    $('#tosc-arrival').val(arrDate[2] + arrDate[1] + arrDate[0]);

    var diffDays = Math.round(Math.abs((arrTime.getTime() - depTime.getTime()) / (oneDay)));
    console.log(diffDays);

    $('#tosc-nights').val(diffDays);

    if( $('.child-age-container').length ){
        var cont = $('.child-age-container select');
        var wert = [];
        for( var kl = 0; kl < cont.length; kl++ ){
            if( $(cont[kl]).val() != 0 ){
                wert.push( $(cont[kl]).val() );
            }
        }
        $('[name="selChildrenSearchLine1"]').remove();
        if( wert.length == 0 ){
            $('#children').after('<input type="hidden" name="selChildrenSearchLine1" value="0">');
        }else {
            $('#children').after('<input type="hidden" name="selChildrenSearchLine1" value="' + wert.join(',') + '">');
        }
    }else{

        //$(this).append('<input type="hidden" name="srchAdult1" value="'+ $('#adults').val() +'">');
        if( $(this).find('#srchChildren1Age1').val() > 0 && $(this).find('#srchChildren1Age2').val() > 0 ){
            $(this).find('#srchChildren1Age1').append('<input type="hidden" name="selChildrenSearchLine1" value="'+$(this).find('#srchChildren1Age1').val()+','+$(this).find('#srchChildren1Age2').val()+'">');
        }else if( $(this).find('#srchChildren1Age2').val() > 0 ){
            $(this).find('#srchChildren1Age2').append('<input type="hidden" name="selChildrenSearchLine1" value="'+ $(this).find('#srchChildren1Age2').val() +'">');
        }else if( $(this).find('#srchChildren1Age1').val() > 0 ){
            $(this).find('#srchChildren1Age1').append('<input type="hidden" name="selChildrenSearchLine1" value="'+$(this).find('#srchChildren1Age1').val()+'">');
        }
    }
    // var formData = _form.serializeArray();
    // window.location = $(this).attr('href') + '?' + $.param( formData );
    // return false;
});
app.modules.ajaxInclude = (function () {
    function ajaxIncludeCallback() {
        var includedContainer = $(this).next();

        if ($(this).is('[data-target]')) {
            var targetContainer = $($(this).data('target'));
            if (targetContainer.length > 0) {
                includedContainer = targetContainer;
            }
        }

        app.initModules(includedContainer);
        $(this).off('ajaxInclude', ajaxIncludeCallback);

        if (_config && _config['debug']) {
            includedContainer.css('outline', '3px solid red');
        }

    }

    return function ($scope) {
        var ajaxIncludeElements = $scope.find('.js-ajaxInclude');



        ajaxIncludeElements.on('ajaxInclude', ajaxIncludeCallback).extendAjaxInclude();
    };
}());

app.modules.lightbox = function ($scope) {
    if (matchMedia('(max-width: 767px)').matches) {
        return;
    }

    $.getScript("/static/js/libs/magnific-popup.js").done(function () {
        var lightboxItems = $scope.find('.js-lightbox'),
            iframes = lightboxItems.filter('.js-lightbox--iframe'),
            imgs = lightboxItems.not('.js-lightbox--iframe, .js-lightbox--webcam'),
            webcams = lightboxItems.filter('.js-lightbox--webcam');

        imgs.magnificPopup(app.modules.lightbox.defaultOptions);

        var iframeOptions = $.extend(true, {}, app.modules.lightbox.defaultOptions);
        iframeOptions.type = 'iframe';
        iframeOptions.iframe = {markup:
            '<div>' +
                '<div class="mfp-close"></div>'+
                '<div class="mfp-iframe-scaler">'+
                    '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                '</div>' +
            '</div>'};

        iframes.magnificPopup(iframeOptions);

        var webcamOptions = $.extend(true, {}, app.modules.lightbox.defaultOptions);
        webcamOptions.type = 'iframe';
        webcamOptions.mainClass = 'mfp-webcam';
        webcamOptions.iframe = {
            markup:
            '<div class="mfp-webcam-holder">' +
                '<div class="mfp-close"></div>'+
                '<div class="mfp-iframe-scaler mfp-iframe-scaler--webcam">'+
                    '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                '</div>' +
            '</div>'};

        webcams.magnificPopup(webcamOptions);
    });
};

app.modules.lightbox.defaultOptions = {
    type: 'image',
    closeOnContentClick: false,
    closeBtnInside: true,
    mainClass: 'mfp-with-zoom mfp-img-mobile',
    closeMarkup: '<button class="mfp-close mfp-new-close" type="button" title="Close (Esc)"> ' +
            '<span class="za-icon za-icon-close text-primary"></span> ' +
        '</button>',

    image: {
        verticalFit: true,
        titleSrc: function(item) {
            if (item.el.attr('title') || item.el.data('title')) {
                return item.el.data('title') || item.el.attr('title') ;
            }
            return '';
        }
    },
    gallery: {
        enabled: true,
        arrowMarkup: '<div tabindex="0" title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir% za-icon za-icon-arrow-%dir%"></div>',
        tCounter: ''
    }
};

app.modules.assetVideoLightbox = function ($scope) {
    if (matchMedia('(max-width: 767px)').matches) {
        return;
    }

    $scope.find('.js-asset-video-lightbox').on( {
        tap: function(evt) {
            evt.preventDefault();

            var $this = $(this),
                popupContentId,
                popupContent = $(this).find('.video-block-content'),
                magnificOptions = $.extend(true, {}, app.modules.lightbox.defaultOptions);

            if (popupContent && popupContent.attr('id')) {
                popupContentId = popupContent.attr('id');
            } else {
                popupContentId = 'asset-video-lightbox-' + app.modules.assetVideoLightbox.videoid++;
                popupContent.attr('id', popupContentId);
            }

            magnificOptions.items =  {
                src: '#'+popupContentId,
                type: 'inline'
            };
            magnificOptions.closeOnContentClick = false;
            magnificOptions.mainClass = 'mfp-asset-video';


            $.getScript('/static/js/libs/magnific-popup.js').done(function () {
                $.getScript('/static/js/libs/video.js').done(function () {
                    $.magnificPopup.open(magnificOptions, 0);
                    var videoEle = popupContent.find('video');
                    if (videoEle && videoEle.length>0) {
                        try {
                            console.warn(videoEle);
                            videoEle[0].play();
                        } catch (e) {}
                    }
                });
            });

            console.log('---');

        },
        click: function (evt) {
            evt.preventDefault();
        }
    });
};
app.modules.assetVideoLightbox.videoid = 0;

app.modules.childCountSelect = function ($scope) {
    $scope.find('.js-childCountSelect').on('change', function () {
        var val = $(this).val();

        var childAgeInputs = $(this).closest('.js-room, form').find('.js-childAgeInputs input');
        childAgeInputs.addClass('hidden');

        for (var i=0; i < childAgeInputs.length && i < val; i++) {
            childAgeInputs.eq(i).removeClass('hidden');
        }
    });
};

app.modules.nightSelect = function ($scope) {
    $('.js-night-select').each(function () {
        var $this = $(this),
            fromInput = $($this.data('from')),
            toInput = $($this.data('to'));

        toInput.on('change', function () {
            var fromValArr = fromInput.val().split('.'),
                fromDate = new Date(fromValArr[2], fromValArr[1]-1, fromValArr[0]),
                toValArr = toInput.val().split('.'),
                toDate = new Date(toValArr[2], toValArr[1]-1, toValArr[0]),
                difference = (toDate - fromDate) /1000/60/60/24;

            console.warn(difference);
            var options = $this.find('option');
            options.attr('disabled', null);
            options.each(function () {
                if ($(this).attr('value') > difference) {
                    $(this).attr('disabled', 'disabled');
                }else if ($(this).attr('value') == difference){
                    $(this).attr('selected', 'selected');
                }
            });

            if (options.filter('[disabled]:selected').length > 0) {
                var t = options.filter(':not([disabled])').last().attr('selected', 'selected');
            }
        });
    });
};

app.modules.checkboxGroup = function ($scope) {
    $scope.find('.js-checkbox-group').on('change', function () {
        if ($(this).is(':checked')) {
            var groupName = $(this).attr('name'),
                allCheckbox = $('.js-checkbox-group-all[name="' + groupName + '"]');

            allCheckbox.attr('checked', null);
        }
    });

    $scope.find('.js-checkbox-group-all').on('change', function () {
        if ($(this).is(':checked')) {
            var groupName = $(this).attr('name'),
                checkboxes = $('.js-checkbox-group[name="' + groupName + '"]');

            checkboxes.attr('checked', null);
        }
    });
};

app.modules.roomFormRepeater = function () {
    $('.js-room__add').on('click', function (evt) {
        evt.preventDefault();
    }).on('tap', function (evt) {
        evt.preventDefault();

        var form = $(this).closest('form'),
            roomRows = form.find('.js-room'),
            firstRoomRow = roomRows.eq(0),
            newRoomRow = firstRoomRow.clone();

        newRoomRow.find('.js-room__count').html(roomRows.length+1);
        newRoomRow.find('.js-room__label').each(function () {
            $(this).prop('for', $(this).prop('for').replace('0', roomRows.length));
        });
        newRoomRow.find('.js-room__input').each(function () {
            $(this).prop('id', $(this).prop('id').replace('0', roomRows.length));
            $(this).prop('name', $(this).prop('name').replace('0', roomRows.length));
        });

        app.modules.childCountSelect(newRoomRow);
        newRoomRow.find('.js-childCountSelect').trigger('change');
        roomRows.eq(roomRows.length-1).after(newRoomRow);
    })
};

app.modules.parsley = function ($scope) {
    var parsleyOptions = {
        successClass: 'has-success',
        errorClass: 'has-error',
        classHandler : function( _el ){
            return _el.$element.closest('.form-group');
        },
        errorsContainer: function (_el) {
            return _el.$element.closest('.form-group');
        }
    };

    $.getScript('/static/js/libs/parsley.min.js').done(function () {
        if (_cfg.lang.toLowerCase() !== 'en') {
            $.getScript('/static/js/libs/i18n/' + _cfg.lang + '.js');
        }

        $scope.find('.js-parsley').parsley(parsleyOptions);
    });
};

app.modules.actionChanger = function ($scope) {
    $scope.find('.js-action-changer').on('submit', function () {
        var newAction = $(this).data('action');

        if (newAction) {
            $(this).attr('action', newAction);
        } else {
            $(this).attr('action', '');
        }
    });
};

app.modules.video = function ($scope) {
    app.modules.video.id || (app.modules.video.id = 1);
    $.getScript('/static/js/libs/video.js').done(function (){
        $scope.find('.video-js').each(function () {
            var id = $(this).prop('id');
            if (!id) {
                id = 'video-js-id-' + app.modules.video.id++;
                $(this).prop('id', id);
            }
            videojs(id);
        });
    });
};

// app.modules.iframeTracking = function($scope) {
//     $('.js-iframe-tracking').each(function () {
//         $this = $(this);
//         if ($this.data('src')) {
//             ga(function(tracker) {
//                 var linker = new window.gaplugins.Linker(tracker);
//                 var output = linker.decorate( $this.data('src') );
//                 $this.attr('src', output );
//             });
//         }
//     });
// };

app.initModules = function ($scope) {
    if(!$scope) {
        $scope = $('body');
    }
    //init all modules if the right _cfg key is set for them
    for (var module in _cfg) {
        if (_cfg[module] && app.modules[module] && typeof  app.modules[module] === 'function') {
            app.modules[module]($scope);
        }
    }
};

app.initSearch = function () {
    var searchForm = $('#main-search-form'),
        searchInput = $('#main-search-input');

    $('.js-toggleSearchBtn').on('tap', function (evt) {
        searchForm.toggleClass('open');
        if (searchForm.hasClass('open')) {
            // prevent input form being displayed to early (chrome & IE)
            evt.preventDefault();

            // set focus after animation has finished
            var timeOut = setTimeout(function () {
                searchInput.focus();
                timeOut = null;
            }, 250);
        }
    });
};

app.mobileNav = function () {
    if(matchMedia('(max-width: 767px)').matches) {
        var mobileNavPlaceholder = $('#mobile-nav-placeholder'),
            mobileNav = $('#main-nav').clone();

        mobileNavPlaceholder.replaceWith(mobileNav);

        var snapper = new Snap({
            element: $('.page-wrapper')[0],
            dragger: $('.navbar-toggle')[0],
            disable: 'left',
            minPosition: -295,
            maxPosition: 295,
            touchToDrag: false
        });

        $('.navbar-toggle').on('tap', function () {
            if (snapper.state().state === 'closed') {
                snapper.open('right');
            } else {
                snapper.close('right');
            }
        }).on('click', function (evt) {
            evt.preventDefault();
        });

        mobileNav.find('.mobile-nav__dropdown-icon').on('tap', function (evt) {
            var parent = $(this).closest('li');

            parent.toggleClass('is-open');
            evt.preventDefault();
        }).on('click', function (evt) {
            evt.preventDefault();
        });
    }
};

app.touchNav = function () {
    var mainNavItems = $('#main-nav>li'),
        mainNavLinks = $('#main-nav>li>a');

    mainNavLinks.on('tap', function (evt) {
        if (window.TouchEvent && evt.originalEvent instanceof TouchEvent) {
            var parent = $(this).parent();

            if (!parent.hasClass('is-open')) {
                mainNavItems.removeClass('is-open');
                parent.addClass('is-open');
                evt.preventDefault();
                evt.stopImmediatePropagation();
                
                $('body').on('tap', bodyClickHandler);
            }
        }
    });

    function bodyClickHandler (evt) {
        if ($(evt.target).parents('#main-nav').length < 1) {
            evt.preventDefault();
            mainNavItems.removeClass('is-open');
            $('body').off('tap', bodyClickHandler);
        }
    }
};

app.socialShare = function () {
    setTimeout(function () {
        $('body').append($('<script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5475a1642f73b8f6" async="async"></script>'));
    }, 5000);
};

// init js; should only be called once; after the page has finished loading.
// to init modules use app.initModules()
app.init = function () {
    // Enable jQuery default cache option globally.
    $.ajaxSetup({
        cache: true
    });

    // postpone init to include modules from script.js.
    var timeout = setTimeout(function () {
        app.initModules();
        clearTimeout(timeout);
    }, 0);

    app.touchNav();
    app.mobileNav();
    app.initSearch();

    if (_config.socialShare) {
        app.socialShare();
    }

};

app.init();
